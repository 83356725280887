<template>
  <div class="transport-statistical-analysis">
    <div class="left-pane">
      <div class="driver-and-car">
        <div class="d-a-c-item">
          <img src="imgs/transport/car.png" alt="" />
          <div class="d-a-c-data">
            <p class="num car-num">{{ carStatus.workCarCount }}</p>
            <p>在途车辆</p>
          </div>
          <div class="d-a-c-data">
            <p class="num car-num">{{ carStatus.notWorkCarCount }}</p>
            <p>空闲车辆</p>
          </div>
          <div class="d-a-c-data">
            <p class="num car-num">{{ carStatus.stopCarCount }}</p>
            <p>停业车辆</p>
          </div>
        </div>
        <div class="d-a-c-item">
          <img src="imgs/transport/driver.png" alt="" />
          <div class="d-a-c-data">
            <p class="num driver-num">{{ driverStatus.workCarCount }}</p>
            <p>在途司机</p>
          </div>
          <div class="d-a-c-data">
            <p class="num driver-num">{{ driverStatus.notWorkCarCount }}</p>
            <p>空闲司机</p>
          </div>
          <div class="d-a-c-data">
            <p class="num driver-num">{{ driverStatus.stopCarCount }}</p>
            <p>停业司机</p>
          </div>
        </div>
      </div>
      <div class="transfer-order">
        <div class="head-box">
          <p class="title">转运单变化曲线</p>
          <div class="filter-box">
            <span
              :class="[
                'filter-item',
                transferOrderType == 'day' ? 'filter-item-active' : '',
              ]"
              @click="() => transferOrderCut('day')"
              >日</span
            >
            <span
              :class="[
                'filter-item',
                transferOrderType == 'week' ? 'filter-item-active' : '',
              ]"
              @click="() => transferOrderCut('week')"
              >周</span
            >
            <span
              :class="[
                'filter-item',
                transferOrderType == 'month' ? 'filter-item-active' : '',
              ]"
              @click="() => transferOrderCut('month')"
              >月</span
            >
            <span
              :class="[
                'filter-item',
                transferOrderType == 'year' ? 'filter-item-active' : '',
              ]"
              @click="() => transferOrderCut('year')"
              >年</span
            >
            <DatePicker
              type="daterange"
              style="width: 200px"
              v-model="transferOrderDate"
            ></DatePicker>
          </div>
        </div>
        <div class="chart" ref="transferOrderChart"></div>
      </div>
      <div class="manure-transfer">
        <div class="head-box">
          <p class="title">转运粪肥统计</p>
          <div class="filter-box">
            <span
              :class="[
                'filter-item',
                transType == 'day' ? 'filter-item-active' : '',
              ]"
              @click="() => transCut('day')"
              >日</span
            >
            <span
              :class="[
                'filter-item',
                transType == 'week' ? 'filter-item-active' : '',
              ]"
              @click="() => transCut('week')"
              >周</span
            >
            <span
              :class="[
                'filter-item',
                transType == 'month' ? 'filter-item-active' : '',
              ]"
              @click="() => transCut('month')"
              >月</span
            >
            <span
              :class="[
                'filter-item',
                transType == 'year' ? 'filter-item-active' : '',
              ]"
              @click="() => transCut('year')"
              >年</span
            >
            <DatePicker
              type="daterange"
              style="width: 200px"
              v-model="transfeFenfeiDate"
            ></DatePicker>
          </div>
        </div>
        <div class="chart" ref="manureTransferChart"></div>
      </div>
    </div>
    <div class="right-pane">
      <div class="head-box">
        <p class="title">转运排行榜</p>
        <div class="filter-box">
          <span
            :class="[
              'filter-item',
              leaderboardType == 'day' ? 'filter-item-active' : '',
            ]"
            @click="() => leaderboardCut('day')"
            >日</span
          >
          <span
            :class="[
              'filter-item',
              leaderboardType == 'week' ? 'filter-item-active' : '',
            ]"
            @click="() => leaderboardCut('week')"
            >周</span
          >
          <span
            :class="[
              'filter-item',
              leaderboardType == 'month' ? 'filter-item-active' : '',
            ]"
            @click="() => leaderboardCut('month')"
            >月</span
          >
          <span
            :class="[
              'filter-item',
              leaderboardType == 'year' ? 'filter-item-active' : '',
            ]"
            @click="() => leaderboardCut('year')"
            >年</span
          >
        </div>
      </div>
      <div class="ranking-filter">
        <span
          :class="['r-f-item', rankType == 1 ? 'r-f-item-active' : '']"
          @click="() => rankCut(1)"
          >司机排行</span
        >
        <span
          :class="['r-f-item', rankType == 2 ? 'r-f-item-active' : '']"
          @click="() => rankCut(2)"
          >车辆排行</span
        >
      </div>
      <div class="leaderboard">
        <div
          class="leaderboard-item"
          v-for="(item, index) in rankType == 1 ? driverRank : carRank"
          :key="index"
        >
          <img v-if="item.avatar == ''" src="/imgs/empty/empty.png/" alt="" />
          <img v-else :src="getImgUrl(item.servicePath, item.avatar)" alt="" />

          <span>{{ rankType == 1 ? item.driverName : item.carPlate }}</span>
          <span
            :style="{
              color: index < 3 ? '#06BAA1' : '#999999',
              fontSize: '18px',
            }"
            >Top {{ index + 1 }}</span
          >
          <span>{{ item.count }}单</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
export default {
  name: "",
  data() {
    return {
      transferOrderChart: null,
      manureTransferChart: null,
      rankType: 1,
      carStatus: {},
      driverStatus: {},
      transferOrderType: "year",
      transferOrderData: {},
      transferOrderDate: [],
      transfeFenfeiDate: [],

      leaderboardType: "year",
      carRank: [],
      driverRank: [],
      transportTimeLine: [],
      dataX: [],
      dataY: [],
      transType: "year",
    };
  },
  watch: {
    transferOrderDate(n) {
      if (!n[0]) return;
      this.transferOrderType = "custom";
      this.getTransferOrderData();
    },
    transfeFenfeiDate(n) {
      if (!n[0]) return;
      this.transType = "custom";
      this.getTrans();
    },
  },
  methods: {
    getCarStatus() {
      this.$post(this.$api.STATICTICS_ANALYSIS.TRANSPORT_CAR_STATUS).then(
        (res) => {
          this.carStatus = res;
        }
      );
    },
    getDriverStatus() {
      this.$post(this.$api.STATICTICS_ANALYSIS.TRANSPORT_DRIVER_STATUS).then(
        (res) => {
          this.driverStatus = res;
        }
      );
    },
    transferOrderCut(type) {
      if (this.transferOrderType == type) return;
      this.transferOrderType = type;
      this.getTransferOrderData();
    },
    getTransferOrderData() {
      this.$post(this.$api.STATICTICS_ANALYSIS.TRANSPORT_TRANSFER_ORDER, {
        startTime:
          this.transferOrderType == "custom"
            ? moment(this.transferOrderDate[0]).format("YYYY-MM-DD HH:mm:ss")
            : "",
        endTime:
          this.transferOrderType == "custom"
            ? moment(this.transferOrderDate[1]).format("YYYY-MM-DD HH:mm:ss")
            : "",
        type: this.transferOrderType,
      }).then((res) => {
        this.transferOrderData = res;
        this.initTransferOrder();
      });
    },
    initTransferOrder() {
      let xData = [];
      let data = [];
      let list = Object.keys(this.transferOrderData).sort((a, b) => {
        let tempA = a.replace(/-/g, "").replace(/\s*/g, "");
        let tempB = b.replace(/-/g, "").replace(/\s*/g, "");
        return +tempA - +tempB;
      });
      for (let i = 0; i < list.length; i++) {
        let key = this.transferOrderData[list[i]];
        xData.push(list[i]);
        data.push(key);
      }
      if (!this.transferOrderChart) {
        this.transferOrderChart = echarts.init(this.$refs.transferOrderChart);
      }
      let option = {
        grid: {
          top: 20,
          left: 50,
          right: 50,
          bottom: 20,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          data: xData,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEE8E8",
              type: "dashed",
            },
          },
          boundaryGap: false,
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: "#f6f6f6",
            },
          },
        },
        series: {
          type: "line",
          smooth: true,
          data,
          lineStyle: {
            color: "#7A40F2",
          },
          itemStyle: {
            color: "#7A40F2",
          },
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 1,
              x2: 0,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(122, 64, 242, 0)",
                },
                {
                  offset: 1,
                  color: "rgba(122, 64, 242, 0.3)",
                },
              ],
            },
          },
        },
      };
      this.transferOrderChart.setOption(option);
    },
    initManureTransfer() {
      if (!this.manureTransferChart) {
        this.manureTransferChart = echarts.init(this.$refs.manureTransferChart);
      }
      let option = {
        grid: {
          top: 20,
          left: 40,
          right: 20,
          bottom: 20,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          data: this.dataX,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEE8E8",
              type: "dashed",
            },
          },
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: "#f6f6f6",
            },
          },
        },
        color: ["#70D5FA", "#D47AF5", "red"],
        series: [
          {
            type: "bar",
            name: "转运粪肥",
            stack: "1",
            data: this.dataY,
            barMaxWidth: 25,
          },
        ],
      };
      this.manureTransferChart.setOption(option);
    },
    rankCut(type) {
      if (this.rankType == type) return;
      this.rankType = type;
      if (this.rankType == 1) {
        this.getDriverRank();
      } else {
        this.getCarRank();
      }
    },
    leaderboardCut(type) {
      if (this.leaderboardType == type) return;
      this.leaderboardType = type;
      if (this.rankType == 1) {
        this.getDriverRank();
      } else {
        this.getCarRank();
      }
    },
    transCut(type) {
      if (this.transType == type) return;
      this.transType = type;
      this.getTrans();
    },
    getDriverRank() {
      this.$post(this.$api.STATICTICS_ANALYSIS.TRANSPORT_DRIVER_RANK, {
        type: this.leaderboardType,
        pageNo: 1,
        pageSize: 10,
      }).then((res) => {
        this.driverRank = res;
      });
    },
    getCarRank() {
      this.$post(this.$api.STATICTICS_ANALYSIS.TRANSPORT_CAR_RANK, {
        type: this.leaderboardType,
        pageNo: 1,
        pageSize: 10,
      }).then((res) => {
        this.carRank = res;
      });
    },
    getTrans() {
      this.$post(this.$api.STATICTICS_ANALYSIS.TRANSPORT_STATICS_RIGION, {
        startTime:
          this.transType == "custom"
            ? moment(this.transfeFenfeiDate[0]).format("YYYY-MM-DD HH:mm:ss")
            : "",
        endTime:
          this.transType == "custom"
            ? moment(this.transfeFenfeiDate[1]).format("YYYY-MM-DD HH:mm:ss")
            : "",
        type: this.transType,
      }).then((res) => {
        this.transportTimeLine = res.timeLine;
        let a = [];
        let b = [];
        for (let key in this.transportTimeLine) {
          a.push(key);
          b.push(this.transportTimeLine[key]);
        }
        this.dataX = a;
        this.dataY = b;
        this.initManureTransfer();
      });
    },
    resize() {
      this.manureTransferChart.resize();
      this.transferOrderChart.resize();
    },
  },
  mounted() {
    this.initManureTransfer();
    this.getCarStatus();
    this.getDriverStatus();
    this.getTransferOrderData();
    this.getDriverRank();
    this.getTrans();
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>